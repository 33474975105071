$slide-count: 5;
$slide-width: 28rem;

.carousel__wrap {
  align-items: center;
  display: flex;
  flex: 1 1;
  justify-content: center;
  position: relative;
  width: 80%;
  left: 50%;
  transform: translateX(-50%);
  height: 300px;
}

.carousel__inner {
  height: 100%;
  position: relative;
  width: calc(#{$slide-width * 3});
}

.carousel__container {
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.carousel__slide-list {
  height: 100%;
  left: 50%;
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: absolute;
  transform: translateX(-50%);
  width: calc(#{($slide-count + 0.5) * $slide-width * 2});
}

.carousel__slide-item {
  display: inline-block;
  height: 100%;
  margin: 0;
  padding: 1rem;
  position: absolute;
  transition: all 0.3s;
  //width: $slide-width;
  width: 28rem;
}

.carousel__slide-item-img-link {
  cursor: zoom-in;
  display: flex;
  height: 70%;
  overflow: hidden;
  position: relative;
  width: 100%;

  img {
    height: 100%;
    object-fit: cover;
    transition: all 0.5s ease;
    width: 100%;
  }

  &::after {
    align-items: center;
    background: rgba(black, 0.5);
    color: white;
    content: 'Đọc thêm';
    display: flex;
    height: 100%;
    justify-content: center;
    opacity: 0;
    position: absolute;
    transition: all 0.5s ease;
    width: 100%;
  }

  &:hover {
    &::after {
      opacity: 1;
    }

    img {
      transform: scale(1.3);
    }
  }
}

.carousel-slide-item__body {
  bottom: 0px;
  height: 30%;
  position: absolute;
}

.carousel-slide-item__body h4 {
  margin: 0.7rem 0 0;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;
}

.carousel-slide-item__body p {
  font-size: 14px;
  line-height: 1.3;
  margin: 0.7rem 0 0;
  font-family: Arial, Helvetica, sans-serif important;
}


.carousel__btn {
  align-items: center;
  background: 0;
  border: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  //padding: 20px;

  &--prev {
    left: -4rem;
  }

  &--next {
    right: -4rem;
  }
}

.carousel__btn-arrow {
  border: solid black;
  border-width: 0 0.4rem 0.4rem 0;
  height: 30px;
  padding: 3px;
  width: 30px;
  z-index: 10;
  opacity: 0.3;

  &--left {
    transform: rotate(135deg);
  }

  &--right {
    transform: rotate(-45deg);
  }

  &:hover {
    opacity: 1;
  }
}


.carousel__dots {
  display: inline-block;
  left: 50%;
  margin-top: 2rem;
  position: absolute;
  transform: translateX(-50%);
  bottom: -4rem;

  .dot {
    background: #ccc;
    border: 0;
    border-radius: 50%;
    cursor: pointer;
    height: 1.5rem;
    margin: 0 0.3rem;
    outline: none;
    transform: scale(0.5);
    width: 1.5rem;

    &.active {
      background: black;
    }
  }
}

.list-new-10 {
  margin-bottom: 80px;
}
