.contain-contact {
    width: 60% !important;
    height: fit-content;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.btn-submit-contact {
    background-image: -webkit-linear-gradient(0deg, rgb(9 96 160) 0%, rgb(238, 29, 35) 100%);
}

@media screen and (max-width: 1000px) {
    .contain-contact  {
        width: 100% !important;
    }
  }