
.signature {
    display: block;
    margin: 20px auto;
    border: 2px solid red;
    width: 100%;
    height: 180px!important;
}
.box-sign{
    width: 100%;
    height: 100%;
}
.modal-contract{
    /*width: auto;*/
    /*max-width: calc(100vw - 32px);*/
    /*margin: 0 auto;*/
    /*display: flex;*/

}

.cus-modal>.modal-content{
    max-height: 1000px;
    width: 900px!important;
    overflow-x: auto;
    display: flex;
    flex-direction: column !important;
}

.cus-modal-body {
    max-height: 1000px; /* Đặt kích thước tối đa cho nội dung */
    overflow-y: auto; /* Hiển thị thanh cuộn khi nội dung vượt quá kích thước */
}
.sign-contract__container{
    display: flex;
    justify-content: flex-end;
}
.conten-sign{
    width: 400px;
    /*margin-right: 60px;*/
    margin-right: 185px;
}
