.main-nav__content{
    background-color: var(--bs-body-bg);
    padding: 14px 14px 20px 20px;
    /*margin-bottom: 12px;*/
    margin: -6px 17px;
    border-radius: 6px;
}
.content-orders{
    min-height: 530px;
    /*background-color: var(--bs-body-bg);*/
    padding: 14px 20px 20px 14px;

}
.content-orders-lll{
    background-color: var(--bs-body-bg);

}
.page-container{
    /*margin-bottom: 14px;*/
}
/*.search-bar__container{*/
/*    background-color: rgb(234,234,234);*/
/*}*/
.load-more__container{
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
}
.load-more__container Button{
    height: 34px;
    width: 134px;
}
.shop-container{
    background-color: var(--bs-body-bg);
    padding: 14px 14px 14px 20px;
}
