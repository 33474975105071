.pr__container{
    display: flex;
    flex-direction: column;
    min-height:723px;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    line-height: 1.4;
    color: rgb(51, 51, 51);
    padding: 30px 34px 0px 30px;
    height: fit-content;
}
.pr__container div{
    z-index: 2;
}
.pr_line{
    border-bottom: 3px dotted #0960a0;;
}
.pr-it{
    margin-bottom: 10px;
}
.pr-it__title{
    margin-bottom: 30px;
}
.line-fr{
    width: 30%;
    border-bottom: 4px solid #0960a0;
    margin-bottom: 10px;
}
.pr__main-title h2{
    font-size: 30px;
    font-weight: bold;
}
.img-dv__container{
     display: flex;
    justify-content: center;
    position: relative;
    flex: 1;
    min-height: 170px;
    height: fit-content;
 }
.ld__img{
    height: 100%;
    position: absolute;
    bottom: 0;
    height: 100%;
}
.ld__img img{
    height: 100%;
    object-fit: contain;
}
.pri-cus{
    font-weight: bold;
    color: rgb(20, 20, 20);
    text-align: left;
    font-size: 18px;
}
.i-prv{
    height: 150px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    /*border:1px solid #DFDFDF;*/
    /*padding: 1px;*/
    border-radius: 6px;
    margin-bottom: 10px;
}
.i-prv img{
    width: 95%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
}

.pr__c_p{
    padding-right: 40px;
}
.ls-d{
    min-height: 723px;
    height: fit-content !important;
}
