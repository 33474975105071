.sidebar-menu-container{
    background: #fff;
    border-radius: 6px;
}
.product-highlight-name{
    text-decoration: none;
}
.list-nav-arrow{
    padding-left: 12px;
}
/*.box-pattern{*/
/*    background: #fff;*/
/*    border-radius: 6px;*/
/*    padding: 20px 12px 20px 12px;*/
/*}*/
.highlight-prod-title{
    font-weight: 600;
    font-style: normal;
    font-size: 1.5rem;
    line-height: 2rem;
}
.category-nav{
    /*max-height: 477px;*/
}
.sidebar-content {
    max-height: 400px;
    overflow-y: scroll;
    /*height: 477px;*/
    /*overflow: auto;*/
}
/*.price-con{*/
/*    display: flex !important;*/

/*}*/
/*.price-main{*/
/*    font-size: 16px;*/
/*    display: flex;*/
/*}*/
/*.price-main span{*/
/*    font-size: 13px;*/
/*    margin-bottom: 10px;*/
/*}*/
/*.dot{*/
/*    margin:0px 4px 3px 3px*/
/*}*/
