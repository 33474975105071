.ip-search .ant-input-group-addon,
.ip-search .ant-input {
    border: none;
    background: #F5F5F5;
}

.ip-search .ant-input-group-addon{
    border-radius: 20px 0px 0px 20px;
}

.ip-search .ant-input {
    border-radius: 0px 20px 20px 0px;
}


.op-search-header .ant-select-selector {
    border-radius: 24px 0px 0px 24px !important;
}
.op-search-header .ant-select-selector .ant-select-selection-item {
    padding-left: 10px;
    color: rgb(134, 142, 150);
}

.ip-search-header .ant-input-group input {
    border-radius: 0px !important;
    border-left: none;
}