.contact__container{
    /*padding: 0px 24px 12px 12px;*/
    background-color: var(--bs-body-bg);
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
    border-radius: 8px;
    min-height: 324px;
    margin-bottom: 16px
}
.product__container{
    /*padding: 12px 24px;*/
    background-color: var(--bs-body-bg);
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
    border-radius: 8px;
    min-height: 300px;

}
.post-dot{
    height: 3px;
    width: 100%;
    background-position-x: -30px;
    background-size: 116px 3px;
    background-image: repeating-linear-gradient(45deg,#6fa6d6,#6fa6d6 33px,transparent 0,transparent 41px,#f18d9b 0,#f18d9b 74px,transparent 0,transparent 82px);
}
.title__container{
    background-color: var(--bs-body-bg);
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
    min-height: 96px;
    margin-bottom: 12px;
    display: flex;
    align-content: center;
    align-items: center;
}
.title__container h4{
    font-size: 22px;
    /*font-weight: 600;*/
    color:var( --color-primary) ;
    font-family: "DM Sans", sans-serif;
    margin-left: 22px;
}
.contact-information__container{
    padding: 12px 24px;
}
.information-form__container{
    padding: 24px 64px;
}
.required-label::before {
    display: inline-block;
    margin-inline-end: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun,sans-serif;
    line-height: 1;
    content: "*";
}

 /*table */
.table-container table{
    width: 100%;
    padding-left: 64px;
}
.item-name__container{
    display: flex;
    align-items: center;
}
.image__container{
    height: 40px;
    width: 40px;
    /*border: 1px solid #DFDFDF;*/
    margin-right: 10px;
}
.image__container img{
    height: 100%;
    width: 100%;
    object-fit: contain;
}
.name-item{
    margin-bottom: 0px;
}
tr{
    border-bottom: 1px dashed rgba(0,0,0,.09) !important;
    padding-bottom: 20px;
    padding-top: 6px;
}
td {
    padding-bottom: 20px;
    padding-top: 6px;
}
.attr-item{
    display: flex;
}
.attr-item p{
    margin-bottom: 0px;
     margin-right: 6px;
}
.name-item{
    margin-right: 6px;
}