.op-payment-img {
    border-radius: 12px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 10px;
}

/* .active-op-payment {
    box-shadow: #0960a0 0px 0px 0px 2px, #0960a0 0px 4px 6px -1px, #0960a0 0px 1px 0px inset;
} */

.op-payment-img:hover {
    transform: scale(1.05);
}