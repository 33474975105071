.buttonWrapper {
  border-radius  : 6px;
  display        : flex !important;
  align-items    : center;
  font-weight    : 500;
  line-height    : 10px;
  justify-content: center;
  height         : 37px !important;

  .name {
    font-weight: 700;
    line-height: 14px;
  }

  .icon {
    svg {
      height: 18px;
      width : 18px;
    }
  }

  .iconLarge svg {
    height: 18px;
    width : 18px;
  }
}

.disable {
  box-shadow: none !important;
  cursor    : not-allowed;

  &:not(.icon svg path) {
    opacity: 0.7;
  }

  .icon svg path {
    &[fill] {
      fill: #ced4da;
    }

    &[stroke] {
      stroke: #ced4da;
    }
  }
}

@mixin btnType($backgroundColor,
  $borderColor,
  $color,
  $hoverBackground,
  $hoverBorderColor,
  $hoverColor) {
  background  : $backgroundColor !important;
  border-color: $borderColor !important;
  color       : $color !important;

  // &:hover,
  // &:focus {
  //   opacity: 0.8;
  //   background: $hoverBackground !important;
  //   border-color: $hoverBorderColor !important;
  //   color: $hoverColor !important;
  //   span {
  //     color: $hoverColor !important;
  //   }
  // }
  span {
    color: $color !important;
  }
}

.linear {
  @include btnType(linear-gradient(90deg, #0960a0 0%, #ed1117 100%),
    #ffffff,
    #ffffff,
    linear-gradient(90deg, #0960a0 0%, #ed1117 100%),
    #ffffff,
    #ffffff);
}

.primary {
  @include btnType(#0960a0, #0960a0, #ffffff, #0960a0, #0960a0, #ffffff);

  &:hover {
    background: #fff !important;
    color     : #0960a0 !important;

    div span {
      color: #0960a0 !important;
    }

    svg path {
      fill  : #0960a0;
      stroke: #0960a0;
    }
  }
}

.yellow {
  @include btnType(#ffd800, #ffd800, #172b4d, #ffd800, #ffd800, #172b4d);
}

.secondary {
  @include btnType(#b6bcc4, #b6bcc4, #ffffff, #b6bcc4, #b6bcc4, #ffffff);
  font-weight: 700 !important;
}

.closeVND {
  @include btnType(#777779, #777779, #ffffff, #777779, #777779, #ffffff);
}

.green {
  @include btnType(#ffffff, #0d99ff, #0d99ff, #ffffff, #0d99ff, #0d99ff);
}

.third {
  @include btnType(#ffffff, #0960a0, #0960a0, #0e8cf1, #0e8cf1, #ffffff);

  &:hover,
  &:focus {
    background: #0960a0 !important;
    color     : #fff !important;

    div span {
      color: #fff !important;
    }

    svg path {
      fill  : #0960a0;
      stroke: #fff;
    }
  }
}

.third-st {
  @include btnType(#ffffff, #0960a0, #0960a0, #0e8cf1, #0e8cf1, #ffffff);

  &:hover,
  &:focus {
    svg path {
      stroke: white;
    }
  }
}

.fourth {
  @include btnType(#2c2c35, #c4c4c4, #c4c4c4, #2c2c35, #c4c4c4, #c4c4c4);
}

.fifth {
  @include btnType(#ffffff, #8b8a92, #8b8a92, #ffffff, #8b8a92, #8b8a92);
}

.orange {
  @include btnType(#f7941e, #f7941e, #ffffff, #f7941e, #f7941e, #ffffff);
}

.back {
  @include btnType(#f0f0f0, #505050, #505050, #f0f0f0, #505050, #505050);
}

.gray {
  @include btnType(#f2f2f2, #f2f2f2, #f2f2f2, #f2f2f2, #f2f2f2, #f2f2f2);

  .icon svg {
    height: 24px;
    width : 24px;
  }

  height: 40px !important;
}

.primaryFilter {
  @include btnType(#0d99ff, #0d99ff, #ffffff, #0d99ff, #0d99ff, #ffffff);

  .icon svg {
    height: 24px;
    width : 24px;
  }

  height: 40px !important;
  width : 40px !important;
}

.dark {
  @include btnType(#757575, #757575, #ffffff, #757575, #757575, #ffffff);
}

.primary-sm {
  @include btnType(#d3f7ff, #d3f7ff, #0d99ff, #d3f7ff, #d3f7ff, #0d99ff);
}

.danger {
  @include btnType(#ffffff, #e50500, #e50500, #e50500, #e50500, #ffffff);

  &:hover {
    background: #e50500 !important;
    color     : #fff !important;

    div span {
      color: #fff !important;
    }

    svg path {
      fill  : #0960a0;
      stroke: #fff;
    }

    svg path {
      fill: white;
    }
  }
}

.red {
  @include btnType(#e50500, #ffffff, #ffffff, #ffffff, #ffffff, #e50500);

  &:hover {
    svg path {
      fill: e50500;
    }
  }
}

.svg-24 {
  .icon svg {
    height: 24px;
    width : 24px;
  }
}

.svg-18 {
  border-radius   : 50% !important;
  border          : none !important;
  background-color: transparent !important;
  box-shadow      : none !important;
  height          : 32px !important;
  width           : 32px !important;
  min-width       : 32px !important;
  padding-left    : 4px;

  .icon svg {
    height: 18px;
    width : 18px;
  }

  &:hover {
    background: #cff4e1 !important;
  }

  &.zoomIn svg {
    animation-name    : zoomIn;
    animation-duration: 0.5s;
  }
}

.btn-circle {
  border-radius   : 50% !important;
  border          : none !important;
  background-color: white;
  box-shadow      : none !important;
  height          : 30px !important;
  width           : 30px !important;
  box-shadow      : 0px 2px 5px rgba(187, 199, 208, 0.5);
  min-width       : 20px !important;

  .icon svg {
    height: 20px;
    width : 20px;
  }

  // &:hover {
  //   background: #cff4e1 !important;
  // }
  &.zoomIn svg {
    animation-name    : zoomIn;
    animation-duration: 0.5s;
  }
}

.minWidth {
  min-width: 84px;
}

@keyframes zoomIn {
  from {
    width : 0px;
    height: 0px;
  }
}