.shop-container {
    display: flex;
    padding-bottom: 4px;
    border-bottom: 1px solid #DFDFDF;
}

.shop-container-item {
    display: flex;
}
.shop-container-item__cus{
    flex-wrap: wrap;
}

.shop-container-item h5 {
    display: flex;
    font-size: 16px;
    margin-bottom: 0px;
    align-self: center;
    font-weight: 600;
}

.item__name {
    margin-right: 6px;
}

.content-order__container {
    padding: 12px 0px;
    background-color: var(--bs-body-bg);
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
    border-radius: 8px;
}

.order-container__main {
    display: flex;
}
.order-container__main_l{
    flex-wrap: nowrap;
}
.image-order__container {
    height: 90px;
    width: 90px;
    margin-right: 8px;
    border: 1px solid #DDD;
}

.image-order__container img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.attribute__container {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    padding: 0 8px;
    /*flex-direction: column;*/
}

.max-line1 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;
}

.max-line2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.max-line3 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3 !important; /* number of lines to show */
    line-clamp: 3 !important;
    -webkit-box-orient: vertical;
}

.content-order__container > ul {
    margin-bottom: 0px;
}

.quantity-price__container {
    display: flex;

}

.price-item__detail {
    display: flex;
    justify-content: flex-end;
}

.content-order__container > ul > .it {
    border-bottom: 1px solid #ddd;
    margin-bottom: 12px;
    cursor: pointer;
}



.content-order__container > ul > .it:last-child {
    border-bottom: none;

}

.car-item-main__container {
    margin-bottom: 20px;
}

.card-item__container {
    margin-bottom: 2px;
}

.total--bill__container {
    padding: 12px 0px;
    background-color: rgb(255, 254, 251);
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
    border-radius: 8px;
    display: flex;
    justify-content: flex-end;
    align-content: center;
    align-items: center;
}

.button__containe {
    display: flex;
}

.total-price__container {
    display: flex;
    justify-content: flex-end;
    margin-left: 34px;
}

.total-title {
    margin-right: 10px;
}

.max-line1 {
    margin-bottom: 0px;
}

.status-title {
    display: flex;
    justify-content: flex-end;
}

.status-title > div {
    margin-right: 10px;
}
.shop-name__container{
    display: flex;
}
.shop-name__container__item{
    display: flex;
    flex-direction: column;
}

.line-1{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    visibility: visible;
    white-space: break-spaces;
    max-width: 100%;
    overflow: hidden;
    height: 28px;
}

.at{
    color: red;
}
.price-total-container{
    flex-wrap: wrap;
}
.mp{
    margin-right: 0px !important;
    color: red;
}
.box-item__container__ad{
    width: 100%;
    display: flex;
}
