/* @import url("../ProductDetail/style.css"); */

.ctn-product-shop-list{
    width: 100%;
    height: fit-content;
}

.ctn-product-shop-list .ctn-product-infor-shop {
    height: 222px;
    width: 80%;
    background: #F0C8C8;
    margin: auto;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.ctn-product-shop-list .ctn-product-infor-shop .ctn-layout {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.ctn-layout .infor-left {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ctn-layout .infor-right {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.ctn-product-shop-list .line {
    height: 90%;
    width: 1px;
    background: #000000;
}

.infor-right .shop-infor{
    width: 370px;
    height: 190px;
    border-radius: 8px;
    background: #FFFFFF;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.ctn-spin-shop-infor {
    padding: 0px 32px 32px 32px;
}

.ctn-product-content-list,
.ctn-spin-shop-infor {
    display: flex;
    justify-content: center;
}

.txt-product-name {
    width: 100%;
    height: 40px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.txt-productshop-name {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.txt-result-search {
    height: 33px;
    line-height: 33px;
}
