/* For demo */
.ant-carousel .slick-slide {
    text-align: center;
    height: 100%;
    line-height: 160px;
    background: #364d79;
    overflow: hidden;
    border-radius: 6px;
}

.ant-carousel .slick-slide h3 {
    color: #fff;
}
.slide-container-detail{
    height: 100% !important;
    /*border-radius: 5px;*/
    border-radius: 6px;
}
.slide-detail img{
    height: 100% !important;
    width: 100% !important;
    object-fit: contain !important;
}
.slick-list
{
    height: 100% !important;
}
.slick-slide{
    height: 100% !important;
}
.slick-dots li.slick-active button:before {
    opacity: .75;
    color: black;
}
.box-swiper >.ant-carousel{
    height: 100%;
}
.slick-track{
    height: 100%;
}
.slick-current div{
    height: 100%;
}
.slick-slide div{
    height: 100%;
}
