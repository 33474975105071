.price-con{
    display: flex !important;

}
.price-main{
    font-size: 16px;
    display: flex;
}
.price-main span{
    font-size: 13px;
    margin-bottom: 10px;
}
.dot{
    margin:0px 4px 3px 3px
}
.old-sale-price{
    font-size: 15px;
    margin-top: 4px;
}
.old-sale-price p{
    margin-bottom: 3px;
}
.txt-product-name {
    width: 100%;
    height: 40px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.txt-productshop-name {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.ctn-product .card-product{
    border: none;
}

.ctn-product:hover .card-product{
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}