.customer__infor-ctn{
    border-right: 1px solid #DFDFDF;
    margin-right: 16px;
    margin-left: 32px;
}
.money-now {
    font-size: 18px;
    font-weight: bold;
    color: #ff4d4f;
}
