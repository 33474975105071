a
abbr
acronym
address, applet, article, aside, audio, b, big, blockquote, body, button, canvas, caption, center, cite, code, dd, del, details, dfn, div, dl, dt, em, embed, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, html, i, iframe, img, input, ins, kbd, label, legend, li, mark, menu, nav, object, ol, output, p, pre, q, ruby, s, samp, section, select, small, span, strike, strong, sub, summary, sup, table, tbody, td, textarea, tfoot, th, thead, time, tr, tt, u, ul, var, video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#SECTION159 > .ladi-section-background {
    background-image: url(https://w.ladicdn.com/s1440x723/57b167c9ca57d39c18a1c57c/tech-why-bg.png);
    /*min-width: 768px;*/
}
.ladi-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
}
#SECTION159 > .ladi-overlay {
    background-color: rgba(255, 255, 255, 0.67);
}
.ladi-section .ladi-section-background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    overflow: hidden;
}

.ladi-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
}
.ladi-section .ladi-container {
    width: 1200px;
}

#SECTION159 > .ladi-section-background, #SECTION464 > .ladi-section-background {
    background-size: cover;
    background-origin: content-box;
    background-position: 0% 0%;
    background-repeat: no-repeat;
    background-attachment: scroll;
}

#SECTION159 {
    height: 723px;
    /*min-width: 768px;*/
}

.ladi-section {
    margin: 0 auto;
    position: relative;
}

.ladi-section > .ladi-container {
    width: 1200px;
}

.ladi-container {
    position: relative;
    margin: 0 auto;
    height: 100%;
}

.ladi-section {
    margin: 0 auto;
    position: relative;
}

#LINE160 {
    top: 175px;
    left: 120px;
    /*min-width: 768px*/
}

#LINE160 {
    width: 51px;
}

.ladi-element {
    position: absolute;
}

#LINE109 > .ladi-line
#LINE160 > .ladi-line
#LINE168 > .ladi-line
#LINE172 > .ladi-line
#LINE176 > .ladi-line
#LINE180 > .ladi-line
#LINE560 > .ladi-line
#LINE564 > .ladi-line
#LINE472 > .ladi-line
#LINE478 > .ladi-line
#LINE485 > .ladi-line
#LINE492 > .ladi-line
#LINE335 > .ladi-line
#LINE345 > .ladi-line
#LINE355 > .ladi-line
#LINE365 > .ladi-line
#LINE375 > .ladi-line
#LINE385 > .ladi-line
#LINE395 > .ladi-line
#LINE405 > .ladi-line
#LINE415 > .ladi-line
#LINE425 > .ladi-line
#LINE435 > .ladi-line
#LINE445 > .ladi-line
#LINE455 > .ladi-line {
    width: 100%;
    padding: 8px 0px;
}

.ladi-line {
    position: relative;
}

#LINE160 > .ladi-line > .ladi-line-container {
    border-top: 4px solid #0960a0;
    border-right: 4px solid #0960a0;
    border-bottom: 4px solid #0960a0;
    border-left: 0px !important;
}

.ladi-line > .ladi-line-container {
    border-bottom: 0 !important;
    border-right: 0 !important;
    width: 100%;
    height: 100%;
}

#HEADLINE161 {
    top: 77px;
    left: 120px;
    /*min-width: 768px*/
}

#HEADLINE161, #PARAGRAPH162 {
    width: 386px;
    /*min-width: 768px*/
}

.ladi-element {
    position: absolute;
}

#HEADLINE161.ladi-headline {
    line-height: 1.4;
    text-align: left;
}

#HEADLINE161 .ladi-headline {
    font-size: 24px;
    font-weight: bold;
    color: rgb(0, 0, 0);
}

.ladi-headline {
    width: 100%;
    display: inline-block;
    word-break: break-word;
    background-size: cover;
    background-position: center center;
}

.ladi-transition {
    transition: all 150ms linear 0s;
}

#PARAGRAPH162 {
    top: 202px;
    left: 120px;
    /*min-width: 768px;*/
}

#HEADLINE161, #PARAGRAPH162 {
    width: 386px;
    /*min-width: 768px;*/
}

#PARAGRAPH162 .ladi-paragraph {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    line-height: 1.4;
    color: rgb(51, 51, 51);
    text-align: left;
}

.ladi-paragraph {
    width: 100%;
    display: inline-block;
    word-break: break-word;
}

#IMAGE163 {
    width: 258.078px;
    height: 206.399px;
    top: 82px;
    left: 545px;
    /*!*min-width: 768px;*!*/
}

.ladi-element {
    position: absolute;
}

#IMAGE164 {
    top: 82px;
    left: 825.965px;
    /*min-width: 768px*/
}

#IMAGE164 {
    width: 255.078px;
    height: 204px;
}


#GROUP165 {
    top: 363px;
    left: 120px;
    /*min-width: 768px*/
}

#GROUP165, #GROUP169, #GROUP173, #GROUP177 {
    width: 323px;
    height: 140px;
}


#GROUP169 {
    top: 537px;
    left: 120px;
    /*min-width: 768px;*/
}

#GROUP165, #GROUP169, #GROUP173, #GROUP177 {
    width: 323px;
    height: 140px;
}

.ladi-image {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

#IMAGE163 > .ladi-image > .ladi-image-background {
    width: 327.599px;
    height: 218.399px;
    top: -12px;
    left: -69.5209px;
    background-image: url(https://w.ladicdn.com/s650x550/5ce50eeaa6078c5b1f6780ef/lovepik_com-500608939-the-age-of-computer-science-and-technology-education-20191201023001.jpg);
    /*!*min-width: 768px;*!*/
}
#IMAGE164 > .ladi-image > .ladi-image-background {
    width: 306px;
    left: -27px;
    background-image: url(https://w.ladicdn.com/s650x550/5ce50eeaa6078c5b1f6780ef/lovepik_com-500410361-computer-science-and-technology-20191201023209.jpg);
}

.ladi-image .ladi-image-background {
    background-repeat: no-repeat;
    background-position: left top;
    background-size: cover;
    background-attachment: scroll;
    background-origin: content-box;
    position: absolute;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    pointer-events: none;
}
#HEADLINE24, #BOX27, #LIST_PARAGRAPH30, #IMAGE164 > .ladi-image > .ladi-image-background, #FORM_ITEM316 {
    top: 0px;
}

.ladi-group {
    position: absolute;
    width: 100%;
    height: 100%;
}

#PARAGRAPH166, #PARAGRAPH170, #PARAGRAPH174, #PARAGRAPH178 {
    width: 323px;
}

#PARAGRAPH166 > .ladi-paragraph, #PARAGRAPH170 > .ladi-paragraph, #PARAGRAPH174 > .ladi-paragraph, #PARAGRAPH178 > .ladi-paragraph {
    font-size: 18px;
    line-height: 1.2;
    /*min-width: 768px*/
}

#PARAGRAPH166 > .ladi-paragraph, #PARAGRAPH170 > .ladi-paragraph, #PARAGRAPH174 > .ladi-paragraph, #PARAGRAPH178 > .ladi-paragraph {
    font-family: Roboto, sans-serif;
    font-weight: bold;
    color: rgb(20, 20, 20);
    text-align: left;
}

.ladi-paragraph {
    width: 100%;
    display: inline-block;
    word-break: break-word;
}

#PARAGRAPH167, #PARAGRAPH171, #PARAGRAPH175, #PARAGRAPH179 {
    width: 275px;
    top: 44px;
    left: 0px;
}

#PARAGRAPH167 > .ladi-paragraph {
    font-size: 14px;
    line-height: 1.4;
    /*!*min-width: 768px;*!*/
}

#PARAGRAPH167 > .ladi-paragraph {
    font-family: Roboto, sans-serif;
    color: rgb(51, 51, 51);
    text-align: left;
}

#LINE168, #LINE172, #LINE176, #LINE180 {
    width: 257px;
    top: 121px;
    left: 0px;
}

#PARAGRAPH167, #PARAGRAPH171, #PARAGRAPH175, #PARAGRAPH179 {
    width: 275px;
    top: 44px;
    left: 0px;
}

#PARAGRAPH171 > .ladi-paragraph, #PARAGRAPH175 > .ladi-paragraph, #PARAGRAPH179 > .ladi-paragraph {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    line-height: 1.4;
    color: rgb(51, 51, 51);
    text-align: left;
}

#LINE168, #LINE172, #LINE176, #LINE180 {
    width: 257px;
    top: 121px;
    left: 0px;
}

#GROUP173 {
    top: 539px;
    left: 756px;
    /*min-width: 768px*/
}

#GROUP165, #GROUP169, #GROUP173, #GROUP177 {
    width: 323px;
    height: 140px;
}

#IMAGE181 {
    top: 345px;
    left: 457px;
    /*min-width: 768px;*/
}

#IMAGE181, #IMAGE181 > .ladi-image > .ladi-image-background {
    width: 266.234px;
    height: 378px;
}

.ladi-image {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

#IMAGE181 > .ladi-image > .ladi-image-background {
    background-image: url(https://w.ladicdn.com/uploads/images/bfc1d1bf-af34-4fe2-9361-35f7f9120640.png);
}

#IMAGE181, #IMAGE181 > .ladi-image > .ladi-image-background {
    width: 266.234px;
    height: 378px;
}

#LINE168 > .ladi-line > .ladi-line-container, #LINE172 > .ladi-line > .ladi-line-container, #LINE176 > .ladi-line > .ladi-line-container, #LINE180 > .ladi-line > .ladi-line-container {
    border-top: 3px dotted #0960a0;
    border-right: 3px dotted #0960a0;
    border-bottom: 3px dotted #0960a0;
    border-left: 0px !important;
}

#GROUP177 {
    top: 365px;
    left: 756px;
    /*min-width: 768px;*/
}
