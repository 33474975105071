
.signature {
    display: block;
    margin: 20px auto;
    border: 2px solid red;
    width: 100%;
    height: 180px!important;
}
.box-sign{
    width: 100%;
    height: 100%;
}
.modal-contract{
    /*width: auto;*/
    /*max-width: calc(100vw - 32px);*/
    /*margin: 0 auto;*/
    /*display: flex;*/

}

.cus-modal>.modal-content{
    /*max-height: 1000px;*/
    /*width: 900px!important;*/
    /*overflow-x: auto;*/
    /*display: flex;*/
    /*flex-direction: column !important;*/
    max-height: 1000px;
    width: 100%!important;
    overflow-x: auto;
    display: flex;
    flex-direction: column !important;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}


.cus-modal-body{
    max-height: 1000px; /* Đặt kích thước tối đa cho nội dung */
    overflow-y: auto; /* Hiển thị thanh cuộn khi nội dung vượt quá kích thước */
}
.sign-contract__container{
    display: flex;
    justify-content: flex-end;
}
.conten-sign{
    width: 200px;
    margin-right: 185px;
    /*margin-right: 60px;*/
}

.ctn-content-contract-pdf{
    margin: 0px -20px -20px -20px !important;
}

.MsoNormalTable tr{
    border-bottom: none !important;
}