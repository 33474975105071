.shop-img{
    width: 50px;
    height: 50px;
    float: left;
}
.shop-name img{
    height: 14px;
}
.shop-name{
    float: left;
    margin-left: 15px;
    margin-right: 8px;
}
.shop-infor-mb{
    /*min-height: 220px;*/
    height: fit-content !important;
    display: flex;

}
.shop-infor{
    width: 100%;
    padding: 15px;
    padding-top: 30px;
    /*min-height: 220px;*/
    height: 220px;
    border-radius: 8px;
    background: #FFFFFF;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    /*display: flex;*/
}
.shop-information{
    display: flex;
    justify-content: center ;
    align-items: center;

}
.review-rate{
    clear: both;
    display: flex;
    justify-content: space-around;
}
.review-rate p{
    margin: 20px 0px;
    text-align: center;
    width: 50%;
    font-weight: bold;

}
.review-rate p:first-child{
    border-right: 1px solid rgb(204, 204, 204);
}
.shop-button{
    display: flex;
    justify-content: space-around;
}
/* .shop-button div{
    display: flex;
    padding: 6px 9px;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 4px;
    border: 1px solid rgb(13, 92, 182);
    cursor: pointer;
    color: rgb(11, 116, 229);
} */
.button-buy{
    display: flex;
    border-radius: 4px;
}
.button-buy Button{
    height: 42px;
}

.add-to-cart__btn{
    width: 196px;
    margin-right: 14px;
    background: #f2e5e4;
    display: block;
    align-content: center;
    color: red !important;
}

.buy-now__btn{
    width: 100px;
    color: white;
}

.btn-s{
    outline: none;
    cursor: pointer;
    border: 0;
    font-size: .875rem;
    font-weight: 300;
    line-height: 1;
    letter-spacing: 0;
    /*display: flex;*/
    align-items: center;
    justify-content: center;
    transition: background-color .1s cubic-bezier(.4,0,.6,1);
    border: 1px solid rgba(0,0,0,.09);
    border-radius: 2px;
    background: transparent;
    color: rgba(0,0,0,.8);
    width: 32px;
    height: 32px;

}
.input-s{
    text-align: center;
    width: 38px !important;
}
.title-content{
    margin-bottom: 12px !important;
}
.title-rate-container{
    display: flex;
    flex-direction: column;
}
.rating-text{
    font-size: 16px;
}
.container-attr{
    display: flex;
    flex-direction: column;
}
.quantity-container{
    display: flex;
    flex-direction: column;
}
.text-quantity{
    margin-bottom: 10px;
}
.product-price{
    padding: 30px 10px 10px 10px;
    /*background: rgba(250, 251, 252, 0.49);*/
}
.images-container{
    height: 350px !important;
    width: 350px !important;
    border: 1px solid #DFDFDF;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
    padding: 10px 10px 10px 10px;
    display: flex;
    justify-content: center;
    /* margin-right: 50px; */
    border-radius: 8px;
}
/* .images-container img{
    width: 95%;
    height: 95%;
    object-fit: contain;
} */
.images-container .img-product-detail {
    height: 100%;
    object-fit: contain;
    width: 100%;
}
.calculator-container{
    margin-bottom: 10px;
    display: flex;
    /*justify-content: space-around;*/
}
.ccbts{
    flex-wrap: wrap;
    margin-bottom: 10px;
}
.tag-container{
    display: flex;
}
.buy-product{
    margin-bottom: 10px;
}
CheckableTag:checked{
    background: #9191fd;
}
.des-title{
    margin-bottom: 0px;
    min-width: 70px;
    text-align: center;
    font-weight: 700;
    font-size:16px;
    font-family: "DM Sans", sans-serif;
    color: #425A8B;
}

.partern{
    box-shadow: 0 1px 1px 0 rgba(0,0,0,.05);
    border-radius: .125rem;
    overflow: hidden;
    background: #fff;
    margin-top: .9375rem;
    padding: 20px 20px 20px 20px;
}
.first-line{
    margin-left: 22px;
}
.price-item-detail__container{
    display: flex;
}
.price-item-detail__container span{
    font-size: 16px;
}
.price-item-detail__container p{
    font-size: 24px;
}
.price-item-detail__container h4:first-child{
    margin-right: 8px;
}
.price-item-detail__container del{
    /*font-size: 22px;*/
    margin-right: 4px;
}
.del-container{
    display: flex;

}
.del-container p{
    margin-bottom: 4px;
    font-size:20px
}

.col-rating-left {
    height: 175px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 11.666667% !important;
    margin-left: 12px;
}

.col-rating-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 175px;
}

.rate-star {
    display: flex;
    width: 300px;
}

.rate-star .product-rate,
.rate-star .txtnumber-rate {
    margin: auto;
}

.progress-star {
    flex: 1;
    margin: 8px 6px;
}

.progress-star .progress-bar {
    background-color: #fabf44;
}

.cmt-product {
    width: 100%;
    height: fit-content;
    border-bottom: 1px solid #dddddd;
    padding: 20px;
}

.rate-option {
    width: 100%;
    display: flex;
    align-items: center;
    height: 45px;
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
}

.time-send {
    padding: 8px 0px 12px 0px;
}

.user-infor-review {
    width: 9.666667% !important;
}

.rating-classification {
    width: 7.666667% !important;
    cursor: pointer;
    height: 100%;
    line-height: 45px;
}

.rating-classification:hover {
    background-color: #f7f7f7;
}

.rating-classification.active{
    border-bottom: 5px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to right bottom, #6a5af9, #f62682);
    background-color: #f7f7f7;
}

.img-review {
    object-fit: cover;
    min-width: 100px;
    min-height: 100px;
    max-width: 250px;
    max-height: 200px;
}

@media screen and (max-width: 1000px) {
    .txt-title-rate {
        width: 25.666667% !important;
    }
    .rating-classification {
        width: 11.666667% !important;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .col-rating-left{
        width: fit-content !important;
    }
}

@media screen and (max-width: 400px) {
    .txt-title-rate {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

/* css xác nhận mua hàng */

.ctn-popupConfirm{
    width: fit-content !important;
    min-width: 400px;
    max-width: 500px;
}

.ctn-popupConfirm .txt-product-item-name {
    margin-left: 20px;
}
.ctn-popupConfirm .ant-modal-body {
    padding: 20px 10px 0px 10px !important;
}

.price-active{
    color: red;
}
.btn-s{
    outline: none;
    cursor: pointer;
    border: 0;
    font-size: .875rem;
    font-weight: 300;
    line-height: 1;
    letter-spacing: 0;
    /*display: flex;*/
    align-items: center;
    justify-content: center;
    transition: background-color .1s cubic-bezier(.4,0,.6,1);
    border: 1px solid rgba(0,0,0,.09);
    border-radius: 2px;
    background: transparent;
    color: rgba(0,0,0,.8);
    width: 32px;
    height: 32px;

}
.input-s{
    text-align: center;
    width: 52px;
}
.price{

}
.shop-name--container{
    display: flex;
}
.shop-name--container span{
    margin-right: 6px;
}
.shop-name--title{
    margin-right: 4px;
    margin-bottom: 0px;
    cursor:pointer;
}
.right-icon{
    opacity: 0.5;
    cursor:pointer;
}

.item-detail--container{
    /*margin-bottom: 20px;*/

}
.table--container{
    max-height: 600px;
    width: 100%;
    overflow-y: scroll;
}
.total-container{
    background: #FFFFFF;
    min-height: 48px;
    display: flex;
    align-content: center;
    align-items: center;
    margin-bottom: 32px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 6px;
}

.price{
    display: flex;
    align-items: center;
}
.price-total-container{
    display: flex;
    /*justify-content: space-around;*/
    align-content: center;
    align-items: center;
    justify-content: flex-end;
}
.total-title{
    display: block;
    font-weight: 600;
    margin-bottom: 0px;
    color: #212121;
    align-self: center;
}
.button-submit{
    height: 40px;
    width: 148px;
    background: #DD5656;
}
.price-total-container div{
    margin-right: 10px;
}
.price-active-main{
    font-size: 18px;
}
.checkbox-shop{
    margin-left: 10px;
}
.checkbox-prod{
    margin-left: 20px;
}
.main-ck{
    display: flex;
}
.content-pay{

}
.item-payment__infor{
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
}
.item-payment__infor>:first-child{
    font-weight: 600;
    /*padding-bottom: 5px;*/
}

.total-border-cf-product {
    border-top: 1px dotted var(--bs-primary);
    padding-top: 16px;
    margin-bottom: unset !important;
}
.head-tit>h5{
    color: var(--bs-primary) !important;
    font-weight: 600;
    margin-left: 20px;
}
.cart-ct{
  margin-right: 12px;
}
.cart-contact-cf-product{
    padding: 5px 20px ;
    background: #FFFFFF;
    height: fit-content;
    /*margin-top: 33px;*/
    border-radius: 6px;
    /* padding-bottom: 26px; */
}
.main-prdt{
    display: flex;
    flex-wrap: wrap;
}
.main-ck__mobile{
    display: flex;
    flex-direction: column;
}
.btn-snm{
    height: 30px !important;
}

.t__t{
    margin-bottom: 10px;
    padding-bottom: 8px;
}
.prtt{

}
.contai_t_T >.total-title{
    align-self: baseline;
}
.contai_t_T{
    margin-bottom: 14px;
}
.head-tit{
    margin-right: 6px;
    padding: 10px 0px 10px 0px;
    background: #FFFFFF;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}
.border-bt{
    border-bottom: 1px solid #DFDFDF;
    margin: 8px 4px;
}
.rvs-ctn{
    padding-right: 4px;
    /*border-right: 1px solid #DFDFDF;*/
    /*min-height: 80px;*/
    /*height: 40px;*/
}
.p-tag-c{
    margin-bottom: 0px !important;
    margin-top: 0px !important;
}
.shctn{
    margin-right: 10px;
    margin-bottom: 14px;
}
.cstd{
    flex-wrap: wrap;
}

.payment-method {
    padding-top: 10px;
    display: flex;
    justify-content: flex-end;
    width: fit-content;
    cursor: pointer;
}
.txt-payment {
    padding-left: 8px;
}
.txt-payment:hover {
    color: #0960a0;
}
.first-line-mbs{
    width: 100%!important;
}
.success-color{
    color: var(--bs-success) !important;
}
.failed-color{
    color: var(--bs-red) !important;
}
.primary-color{
    color: var(--bs-primary) !important;
}
.waiting-color{
    color: yellow !important;
}
