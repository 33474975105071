.ant-modal-header .ant-modal-title {
  color: #0960a0;
  font-weight: 600;
  font-size: 24px;
}
.ant-modal-header {
  position: relative;
  padding: 20px 0 8px 0;
  
  margin: 0px 20px 0px 20px!important;
  border-bottom: 1px solid #d9d9d9;
}
.ant-modal-header::after {
  content: "";
  background-image: -ms-linear-gradient(0deg, #0960a0 0, #ee1d23 100%);
  display: block;
  height: 5px;
  width: 100%;
  position: absolute;
  bottom: -3px;
  left: 0px;
  width: 100px;
  transition: 0.3s;
  background-image: linear-gradient(90deg, #0960a0 0%, #e4353a 100%);
}
.ant-modal-footer {
  position: relative;
  padding: 10px 0 16px 0;
  margin: 0px 16px 0px 16px;
  border-top: 1px solid #d9d9d9;
}
