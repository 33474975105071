.modal-detail-wallet .ant-modal-content {
    height: 80vh;
}

.bd-dashed-bottom {
    width: 100%;
    border-bottom: 1px dashed rgba(0,0,0,.09);
}

.bd-solid-right {
    border-right: 1px solid rgba(0,0,0,.09) !important;
}