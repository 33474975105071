.main{
    /*height: 588px;*/
}
.price-active{
    color: red;
}
.btn-s{
    outline: none;
    cursor: pointer;
    border: 0;
    font-size: .875rem;
    font-weight: 300;
    line-height: 1;
    letter-spacing: 0;
    /*display: flex;*/
    align-items: center;
    justify-content: center;
    transition: background-color .1s cubic-bezier(.4,0,.6,1);
    border: 1px solid rgba(0,0,0,.09);
    border-radius: 2px;
    background: transparent;
    color: rgba(0,0,0,.8);
    width: 32px;
    height: 32px;

}
.input-s{
    text-align: center;
    width: 52px;
}
.price{

}
.shop-name--container{
    display: flex;
}
.shop-name--container span{
    margin-right: 6px;
}
.shop-name--title{
    margin-right: 4px;
    margin-bottom: 0px;
    cursor:pointer;
}
.right-icon{
    opacity: 0.5;
    cursor:pointer;
}

.item-detail--container{
    /*margin-bottom: 20px;*/

}
.table--container{
    max-height: 600px;
    width: 100%;
    overflow-y: scroll;
}
.total-container{
    background: #FFFFFF;
    min-height: 48px;
    display: flex;
    align-content: center;
    align-items: center;
    /*margin-bottom: 32px;*/
    margin-bottom: 0px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 6px;
}

.price{
    display: flex;
    align-items: center;
}
.price-total-container{
    display: flex;
    /*justify-content: space-around;*/
    align-content: center;
    /*align-items: center;*/
    justify-content: flex-end;
    align-items: end
}
.total-title{
    display: block;
    font-weight: 600;
    margin-bottom: 0px;
    color: #212121;
}
.button-submit{
    height: 40px;
    width: 148px;
    background: #DD5656;
}
.price-total-container div{
    margin-right: 10px;
}
.price-active-main{
    font-size: 18px;
}
.checkbox-shop{
    margin-left: 4px;
}
.checkbox-prod{
    margin-left: 4px !important;
}
.main-ck{
    display: flex;
}
.content-pay{

}
.item-payment__infor{
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
}
.item-payment__infor>:first-child{
    font-weight: 600;
    /*padding-bottom: 5px;*/
}
.total-border{
    border-bottom: 1px dotted var(--bs-primary);
    /*padding-bottom: 5px;*/
}
.head-tit>h5{
    color: var(--bs-primary) !important;
    margin-left: 20px;
}
.cart-ct{
  margin-right: 12px;
}
.cart-contact{
    padding: 10px 20px ;
    background: #FFFFFF;
    height: fit-content;
    /*margin-top: 33px;*/
    border-radius: 6px;
    padding-bottom: 26px;
}
.main-prdt{
    display: flex;
    flex-wrap: wrap;
}
.main-ck__mobile{
    display: flex;
    flex-direction: column;
}
.btn-snm{
    height: 30px !important;
}

.t__t{
    margin-bottom: 10px;
    padding-bottom: 8px;
}
.prtt{

}
.contai_t_T >.total-title{
    align-self: baseline;
    font-size: 15px;
    font-weight: 600;
    font-family: Tahoma;
}
.contai_t_T{
    /*margin-bottom: 14px;*/
    align-items: baseline;
}
.head-tit{
    margin-right: 6px;
    padding: 10px 0px 10px 0px;
    /*background: #FFFFFF;*/
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    background: none;
}
.blank >td{
    background-color: #F0F3F8 !important;
}
