.box__container{
    display: flex;
    justify-content: center;
}
.box-item__container{
    display: flex;
}
.box__item{
    margin-right: 6px;
}
box__container{
    display: flex;
    justify-content: flex-end;
}
.box-item__container{
    display: flex;
    flex-wrap: wrap;
}
.box__item{
    margin-right: 6px;
}
.box__item{
    height: 48px;
    min-width: 196px;
}
.rate-btn{
    background: #0a53be;
    color: white;
}
.sign-btn{
    background: #0a53be;
    color: white;
}
.button--container{
    /*height: 78px;*/
    height: fit-content;
    align-items: center;
    /* align-content: center; */
    display: flex;
    justify-content: flex-end;
}
.contract-container{
    padding: 10px 20px;
}
.contract-container{
    padding: 10px 24px;
}
.contract-c{

}
