/* @import url("../ProductDetail/style.css"); */

.ctn-product-shop-list{
    width: 100%;
    height: fit-content;
}

.ctn-product-shop-list .ctn-product-infor-shop {
    min-height: 222px;
    height: fit-content;
    width: 80%;
    background: #F0C8C8;
    margin: auto;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.ctn-product-shop-list .ctn-product-infor-shop .ctn-layout {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.ctn-layout .infor-left {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ctn-layout .infor-right {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.ctn-product-shop-list .line {
    height: 90%;
    width: 1px;
    background: #000000;
}

.infor-right .shop-infor{
    width: 370px;
    height: 190px;
    border-radius: 8px;
    background: #FFFFFF;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.ctn-spin-shop-infor {
    padding: 0px 32px 32px 32px;
}

.ctn-product-content-list,
.ctn-spin-shop-infor {
    display: flex;
    justify-content: center;
}

.ip-search-shop-list .jErKeo {
    background: none !important;
}

.ip-search-shop-list .kMpEhM {
background: none;
}
.sif{
    width: 100%;
}
.ctdre{
    flex-direction: column !important;
    justify-content: center;
    height: fit-content !important;
}
.ctnsdf{
    display: flex;
    justify-content: space-evenly;
    width: 80% !important;
    height: fit-content !important;
    margin-top: 16px;
    margin-bottom: 5px;
    flex-wrap: wrap;

}
.review-rate{
    width: 100%;
}

.ptg{
    margin-top: 0px;
}
.review-rate p{
    margin: 0px;
}
.cpts{
    align-items: center !important;
    align-content: center !important;
    display: flex !important;
}
.kMpEhM{
    background: none!important;
    width: 80%;
}
.btn-ctsf{
    margin: 10px 4px !important;
}
.slok >.ant-select-selector{
    background-color: #eaeaea !important;
}
.srots-item{
    background: #eaeaea !important;
    border-radius: 6px !important;
    margin: 0px 3px;
}

