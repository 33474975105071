.layout-chatbox {
    position: fixed;
    width: 328px;
    height: 455px;
    right: 10px;
    bottom: 0px;
    background: #F4E9E9;
    z-index: 3;
    border-radius: 5px 5px 0px 0px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.layout-chatbox span {
    font-size: 14px;
    font-weight: 100;
    color: #707991;
}

.layout-chatbox .ctn-chatbox {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.chatbox-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50px;
    background: #FFFFFF;
    border-radius: 5px 5px 0px 0px;
}

.layout-chatbox .chatbox-body {
    flex-grow: 1;
    overflow: auto;
}

.chatbox-content {
    width: 100%;
    flex-grow: 1;
    height: 100%;
    overflow: auto;
}

.chatbox-header .content-header-left {
    display: flex;
    align-items: center;
}

.avt-user {
    width: 48px;
    height: 48px;
    margin: 0px 8px;
}

.avt-user img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: contain;
    border-radius: 50%;
}

.chatbox-header .content-header-left .txt-user-name span {
    font-size: .9375rem;
    font-weight: 600;
    color: #000000;
}

.chatbox-content .txt-mess-content {
    width: 100%;
}

.chatbox-content .txt-mess-content .content {
    background: #FFFFFF;
    max-width: 80%;
    min-width: 22%;
    width: fit-content;
    height: fit-content;
    border-radius: 5px;
    margin: 5px 8px;
}

.chatbox-content .txt-mess-content .content .mess-content{
    padding: 5px 8px 0px 8px;
    margin: unset;
    font-size: 14px;
    direction: ltr;
}

.chatbox-content .txt-mess-content .content .time-send-mess{
    padding: 0px 10px;
    font-size: 10px;
    color: #FFFFFF;
}

.chatbox-content .txt-mess-content.txt-right .content .time-send-mess{
    direction: rtl;
}

.chatbox-content .txt-mess-content .content .txt-color-black{
    color: #000000;
}

.chatbox-content .txt-mess-content.txt-right {
    direction: rtl;
}

.chatbox-content .txt-mess-content.txt-right .content {
    background: #78E378;
}

.icon-ip-chatbox:hover {
    color: #0960a0;
}
.layout-chatbox .chatbox-footer {
    height: fit-content;
    min-height: 39px;
}

.layout-chatbox .chatbox-footer .ip-chatbox {
    border-radius: 0;
}

.layout-chatbox .chatbox-footer .ip-chatbox:focus {
    border-color: none !important;
}

.layout-chatbox .chatbox-footer .ctn-chat-img {
    background-color: white;
}

.layout-chatbox .chatbox-footer .ctn-chat-img .content-img-chat{
    height: 100px;
    width: 100px;
    object-fit: cover;
    border-radius: 10px;
}

.layout-chatbox .chatbox-footer .ctn-chat-img .close-img-chat{
    position: absolute;
    top: 2px;
    left: 88px;
    width: 22px !important;
    height: 22px !important;
}

.layout-chatbox .chatbox-footer .ctn-chat-img .close-img-chat:hover{
    border: 1px solid #d9d9d9 !important;
}