td{
    padding: 0;
    /*display: table-cell;*/
    vertical-align: middle;
}
.item-container{
    display:flex;
    height: 100%;
}
.image--container img{
    height: 100%;
    width: 100%;
    object-fit: contain;
}
.image--container{
    cursor: pointer;
    height: 65px;
    width: 65px;
    margin-right: 42px;
    border: 1px solid #dfdfdf;
    /*padding: 5px;*/
    display: flex;
    align-items: center;
}
.stcs{
    cursor: pointer;
}
.item-content-container{
    display:flex;
    flex-direction:column;
    justify-content: space-between
}

.ant-form-item {
    margin-bottom: 0px;
}
.icon-voucher__container{
    display: flex;
margin-right: 10px;
}
.icon-voucher__container p{
    margin-right: 4px;
    color: var(--bs-primary);
    cursor: pointer;
}
.icon-voucher__container p :hover{
   opacity: 0.8;
}
.p-icon{
    margin-right: 4px;
}
.voucher-dt-container{
    flex: 1;
}
.vc_ctn{
    display: flex;
}