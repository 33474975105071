.quantity-price__container{
    display: flex;
}
.attribute__container{
    width: 100%;
    display: flex;
    align-items: end;
}
.price-dis__container{
    display: flex;
    justify-content: flex-end;
}
.price-dis__container p{
    margin-bottom: 0px;
    /*margin-right: 30px;*/
}
.price{

}
.main-p{
    color: red;
}
.sale-price{
    margin-right: 10px;
}
.main-container{
    padding: 12px 24px;
    background-color: var(--bs-body-bg);
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
    border-radius: 8px;
    /*min-height: 600px;*/
}
.status-tracking{
    padding-top: 10px;
    min-height: 250px;
    /*border-bottom: 1px solid #DFDFDF;*/
    border-top: 1px solid #DFDFDF;
}
.document-container{
    height: 250px;
    /*border-bottom: 1px solid #DFDFDF;*/
}

.status-container{
    border-bottom: 1px solid #DFDFDF;
}
.main-p{
    font-size: 18px;
}
.back-container{
    /*border-bottom: 1px solid #DFDFDF;*/
    min-height: 40px;
   padding: 18px 24px;
    display: flex;
    align-items: center;
    display: flex;
}
.back-item{
    display: flex;
    justify-content: flex-start;
    align-content: center;
}
.back-item h6{
    cursor: pointer;
}
.id-title__container{
    display: flex;
    justify-content: flex-end;
    align-content: center;
}
.id-title__container p{
    margin-bottom: 0;
    margin-right: 20px;
}
.product-detail{
    padding: 4px 24px;
}
.document-container{
    display: flex;
    flex-direction: column;
    height: 100px;
    background: #FFFDE5;
    padding-top: 20px;
}

.body__it{
    display: flex;
    justify-content: flex-start;
    align-content: center;
}
.body__it p{
    margin-left: 20px;
}
.header__it{
    display: flex;
    justify-content: flex-start;
    align-content: center;
    height: 32px;
}
.header__it p{
    font-size: 16px;
    margin-left: 20px;
    margin-top: 6px;
    font-weight: 600;
}
.item-files{
    height: 32px;
    width: 32px;
    margin-left: 12px;
}
.im__p__container{
    display: flex;
    /*margin-right: 20px;*/
}
.image-order__container{
    /*margin-right: 10px;*/
    height: 80px;
    width: 80px;
}
.name_cts{
    display: flex;
    flex-direction: column;
    align-self: center;
}


.price-dis__container{
    flex: 1;
}
.quantity-price__container p{
    /*margin-right: 34px;*/
}
.order-container__main{
    flex-wrap: wrap;
}
.body__it{}
.mcts{
    min-height: 554px;
}

.order-container__main .ic-tag-order {
    padding-right: 5px;
}

.order-container__main .ic-tag-order svg path{
    fill: #000;
}