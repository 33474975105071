.quantity-price__container{
    display: flex;
}
.attribute__container{
    width: 100%;
}
.price-dis__container{
    display: flex;
    justify-content: flex-end;
}
.price-dis__container p{
    margin-bottom: 0px;
    /*margin-right: 30px;*/
}
.price{

}
.main-p{
    color: red;
}
.sale-price{
    margin-right: 10px;
}
.main-container{
    padding: 12px 24px;
    background-color: var(--bs-body-bg);
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
    border-radius: 8px;
    /*min-height: 600px;*/
}
.status-tracking{
    padding-top: 10px;
    min-height: 250px;
    /*border-bottom: 1px solid #DFDFDF;*/
    border-top: 1px solid #DFDFDF;
}
.document-container{
    height: 250px;
    /*border-bottom: 1px solid #DFDFDF;*/
}

.status-container{
    border-bottom: 1px solid #DFDFDF;
}
.main-p{
    font-size: 18px;
}
.back-container{
    /*border-bottom: 1px solid #DFDFDF;*/
    min-height: 40px;
    padding: 18px 24px;
    display: flex;
    align-items: center;
    display: flex;
}
.back-item{
    display: flex;
    justify-content: flex-start;
    align-content: center;
}
.back-item h6{
    cursor: pointer;
}
.id-title__container{
    display: flex;
    justify-content: flex-end;
    align-content: center;
}
.id-title__container p{
    margin-bottom: 0;
    margin-right: 20px;
}
.product-detail{
    padding: 30px 24px 24px 4px;
}
.document-container{
    display: flex;
    flex-direction: column;
    height: 100px;
    background: #FFFDE5;
}

.body__it{
    display: flex;
    justify-content: flex-start;
    align-content: center;
}
.body__it p{
    margin-left: 20px;
}
.header__it{
    display: flex;
    justify-content: flex-start;
    align-content: center;
    height: 32px;
}
.header__it p{
    font-size: 16px;
    margin-left: 20px;
    margin-top: 6px;
    font-weight: 600;
}
.item-files{
    height: 32px;
    width: 32px;
    margin-left: 12px;
}
.box__container{
    display: flex;
    justify-content: flex-end;
}
.box-item__container{
    display: flex;
}
.box__item{
    margin-right: 6px;
}
.box__item{
    height: 48px;
    min-width: 196px;
}
.rate-btn{
    background: #0a53be;
    color: white;
}
.sign-btn{
    background: #0a53be;
    color: white;
}
.button--container{
    min-height: 78px;
    height: fit-content;
    align-items: center;
    /* align-content: center; */
    display: flex;
    justify-content: flex-end;
    padding: 10px 4px;
}
.contract-container{
    padding: 10px 20px;
}
.contract-container{
    padding: 10px 24px;
}
.contract-c{

}

